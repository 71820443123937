/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import "stylesheets/application"

import TurbolinksAdapter from 'vue-turbolinks';
import Vue from 'vue/dist/vue.esm'
import Multiselect from 'vue-multiselect'
import Tooltip from 'vue-directive-tooltip';

document.addEventListener("turbolinks:load", function() {
  if(window.Rewardful) {
    Rewardful.Forms.attach();
  }
});

Vue.use(TurbolinksAdapter)
Vue.use(Tooltip);
Vue.component('multiselect', Multiselect)

Vue.filter('capitalize', function (value) {
  if (!value) return ''
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
})

import AppForm from '../apps/form'
import ClusterForm from '../clusters/form'
import HerokuComparison from '../heroku'

document.addEventListener('turbolinks:load', () => {
  var el = document.querySelector('[data-behavior="app-form"]')
  if (el) {
    const app = new Vue({ el: el, components: { AppForm } })
  }

  var el = document.querySelector('[data-behavior="cluster-form"]')
  if (el) {
    const app = new Vue({ el: el, components: { ClusterForm } })
  }

  var el = document.querySelector('[data-behavior="heroku-comparison"]')
  if (el) {
    new Vue({ el: el, components: { HerokuComparison } })
  }
})
