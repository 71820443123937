<template>
</template>

<script>
export default {
  props: ["clusters"],
  data() {
    return {
      cluster_id: "",
      provider: "",
    }
  },
  computed: {
    cluster: function() {
      return this.clusters.find(cluster => cluster.id == this.cluster_id)
    },

    deploy_key: function() {
      return (this.cluster) ? this.cluster.deploy_key : null
    },

    roles: function() {
      return (this.cluster) ? this.cluster.roles : []
    },

    placeholder: function() {
      return (this.provider == "custom" ? "git@domain.com:repository" : "username/repository")
    }

  },
  methods: {
    databaseAvailable: function(role) {
      if (role == "mysql2") { role = "mysql" }
      let available = this.roles.concat(["external", "none"])
      return available.includes(role)
    }
  }
}
</script>
