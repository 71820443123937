<template>
</template>

<script>
export default {
  props: ['cluster', 'roles', 'editing', 'allowed', 'remaining', 'services'],
  data() {
    if (this.editing) {
      this.cluster.servers.forEach( server => {
        server._destroy = null
        server.original_tags = server.tags
      })
    }

    return {
      object: this.cluster,
      available_sizes: [],
      sizes: [],
      regions: [],
      vpcs: [],
      subnets: [],
      loading_regions: false,
      loading_vpcs: false,
      provider_error: null,
    }
  },

  beforeMount() {
    if (this.cluster.provider) { this.loadProvider() }
  },

  methods: {
    providerChanged() {
      this.regions = []
      this.available_sizes = []
      this.sizes = []
      this.provider_error = null

      // Clear the region if you're creating a new cluster
      if (!this.editing) {
        this.cluster.region = null
        this.object.service_id = null

        // Clear out VPC and subnet IDs when provider changes on a new cluster
        this.object.vpc_id = null
        this.object.subnet_id = null
      }

      // We don't need to load a provider for custom servers
      if (this.cluster.provider == 'custom') { return }
    },

    loadProvider(selectedOption, id) {
      var provider = this.cluster.provider

      // If we're editing, we can pass in the service ID and look that one up specifically
      var url = `/providers/${this.cluster.provider}`
      if (this.cluster.id) {
        url += `?cluster_id=${this.cluster.id}`
      } else if (selectedOption) {
        url += `?service_id=${selectedOption.id}`
      }

      this.loading_regions = true

      $.ajax({
        method: "GET",
        url: url,
        success: (data) => {
          if (provider != this.cluster.provider) { return } // user changed provider before this finished

          this.provider_error = data.error
          this.loading_regions = false
          this.regions = data.regions || []
          this.available_sizes = data.sizes || []

          if (this.editing) {
            // this will update sizes after finding the correct region
            this.regionChanged({id: this.object.region})
          } else {
            // Reset sizes if provider changed
            this.object.servers.forEach(server => {
              server.size = this.sizes.find(size => size.id == server.size)
            })
          }

        }
      })
    },

    regionChanged(selectedOption) {
      this.sizes = []

      var id = parseInt(selectedOption.id)
      if (isNaN(id)) { id = selectedOption.id }

      this.available_sizes.forEach(size => {
        if (size.regions.includes(id)) {
          this.sizes.push(size)
        }
      })

      if (this.editing) {
        this.object.servers.forEach(server => {
          server.size = this.sizes.find(size => size.id == server.size)
        })
      }

      if (this.object.provider == 'ec2') {
        this.vpcs    = []
        this.subnets = []
        this.object.vpc_id = { id: null, name: "Create & configure a VPC for me", subnets: [] }
        this.object.subnet_id = null

        this.loading_vpcs = true

        // Region only changes on new clusters
        var service_id = (this.object.service_id && this.object.service_id.id)
        var url = `/vpcs?region=${id}&service_id=${service_id}`

        $.ajax({
          method: "GET",
          url: url,
          success: (data) => {
            this.vpcs = data.vpcs || []
            this.vpcs.unshift({ id: null, name: "Create & configure a VPC for me", subnets: [] })
            this.loading_vpcs = false
          }
        })
      }
    },

    vpcChanged(selectedOption) {
      this.subnets = selectedOption.subnets
      this.cluster.subnet_id = this.subnets[0]
    },

    singleServerTemplate() {
      var name = this.object.name.toLowerCase().replace(/\W+/g, "-")
      var size = this.sizes[0]
      this.object.servers.push({
        name: name,
        tags: ["web", "cron", "worker", "postgresql", "redis"],
        size: size,
        volume_size: null,
        public_ip: null,
        private_ip: null,
        port: 22,
      })
    },

    separateDatabaseTemplate() {
      var name = this.object.name.toLowerCase().replace(/\W+/g, "-")
      var size = this.sizes[0]
      this.object.servers.push({ name: `${name}-worker1`, tags: ["web", "worker", "cron"], size: size, volume_size: null, public_ip: null, private_ip: null })
      this.object.servers.push({ name: `${name}-db`, tags: ["postgresql", "redis"], size: size, volume_size: null, public_ip: null, private_ip: null })
    },

    loadBalancerTemplate() {
      var name = this.object.name.toLowerCase().replace(/\W+/g, "-")
      var size = this.sizes[0]
      this.object.servers.push({ name: `${name}-lb`, tags: ["load_balancer"], size: size, volume_size: null, public_ip: null, private_ip: null })
      this.object.servers.push({ name: `${name}-web1`, tags: ["web"], size: size, volume_size: null, public_ip: null, private_ip: null })
      this.object.servers.push({ name: `${name}-web2`, tags: ["web"], size: size, volume_size: null, public_ip: null, private_ip: null })
      this.object.servers.push({ name: `${name}-worker1`, tags: ["worker", "cron"], size: size, volume_size: null, public_ip: null, private_ip: null })
      this.object.servers.push({ name: `${name}-db`, tags: ["postgresql", "redis"], size: size, volume_size: null, public_ip: null, private_ip: null })
    },

    addServer() {
      var size = this.sizes[0]
      this.object.servers.push({
        name: null,
        tags: [],
        size: size,
        volume_size: null,
        public_ip: null,
        private_ip: null
      })
    },

    removeServer(event, index) {
      event.preventDefault()

      if (this.editing) {
        if (this.object.servers[index].id != null) {
          this.object.servers[index]._destroy = 1
        } else {
          this.object.servers.splice(index, 1)
        }
      } else {
        this.object.servers.splice(index, 1)
      }
    },

    undoRemoveServer(event, index) {
      event.preventDefault()
      this.object.servers[index]._destroy = null
    },

    removeTag(option, id, index) {
      var unremovable = ["postgresql", "mysql", "mongodb"]
      var server = this.object.servers[index]

      // If this is an existing server, we don't allow removing certain tags
      // and it must have been one of the original tags
      if (this.editing && server.id && server.original_tags.includes(option) && unremovable.includes(option)) {
        this.object.servers[index].tags.push(option)
      }
    },

    submitForm(event) {
      event.preventDefault()

      var region_id  = (this.object.region && this.object.region.id)
      var service_id = (this.object.service_id && this.object.service_id.id)
      var servers_attributes = []
      this.object.servers.forEach( server => {
        servers_attributes.push({
          id: server.id,
          name: server.name,
          tags: server.tags,
          size: (server.size && server.size.id),
          volume_size: server.volume_size,
          public_ip: server.public_ip,
          private_ip: server.private_ip,
          port: server.port,
          _destroy: server._destroy,
        })
      })

      var data = {}

      // Dont' include any of these when editing a cluster because we want to keep the original values from create
      if (this.editing) {
        Object.assign(data, {
          cluster: {
            name:       this.object.name,
            servers_attributes: servers_attributes,
          }
        })

      } else {
        Object.assign(data, {
          cluster: {
            name:       this.object.name,
            startup_script_id: (this.object.startup_script_id && this.object.startup_script_id.id),
            servers_attributes: servers_attributes,
            provider:   this.object.provider,
            service_id: service_id,
            region:     region_id,
            vpc_id:     (this.object.vpc_id && this.object.vpc_id.id),
            subnet_id:  (this.object.subnet_id && this.object.subnet_id.id),
          }
        })
      }

      var url    = this.editing ? `/clusters/${this.cluster.id}/modify` : "/clusters"
      var method = this.editing ? "PUT" : "POST"

      $.ajax({
        url: url,
        method: method,
        dataType: "script",
        data: data,
        headers: {
          'X-CSRF-Token': $( 'meta[name="csrf-token"]' ).attr( 'content' ),
        },
        error: (error) => {
          var responseText = error.responseText
          $("body").html(
            responseText.match(
              /<body[^>]*>((.|[\n\r])*)<\/body>/im
            )[0]
          );
          Turbolinks.dispatch("turbolinks:load");

          document.body.scrollTop = 0; // For Safari
          document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        },
      })
    },

    serversWithTag(tag) {
      var servers = 0
      this.validServers.forEach(server => {
        if (server.tags.includes(tag)) { servers += 1 }
      })
      return servers
    },
  },

  computed: {
    servicesForProvider() {
      return this.services.filter(service => service.provider == this.object.provider)
    },

    serversAdded() {
      var count = 0
      this.object.servers.forEach(server => {
        if (server.id == null && server._destroy == null) { count += 1 }
        if (server.id != null && server._destroy == 1) { count -= 1 }
      })
      return count
    },

    valid() {
      // maximum 1 load balancer
      // 1+ web server is required
      // 1 cron server is required
      // 1 redis server is required (for ActionCable)
      // If load balancer, 1 (separate) web server is required
      // Validates public ip and private ip for custom servers

      return !!this.object.provider &&
        (this.serversAdded <= this.remaining) &&
        (this.object.provider == 'custom' || !!this.object.region) &&
        this.validServers.length > 0 &&
        this.validWebServers &&
        this.validCron &&
        this.validRedis &&
        this.validWorkerServers &&
        this.validLoadBalancer &&
        this.validVPC
    },

    validServers() {
      var servers = []
      this.object.servers.forEach(server => {
        if (server._destroy != 1) { servers.push(server) }
      })
      return servers
    },

    validWebServers() {
      return this.serversWithTag("web") >= 1
    },

    validCron() {
      return this.serversWithTag("cron") == 1
    },

    validRedis() {
      return this.serversWithTag("redis") == 1
    },

    validWorkerServers() {
      return this.serversWithTag("worker") >= 1
    },

    validLoadBalancer() {
      let count = this.serversWithTag("load_balancer")

      if (count == 0) {
        return true

      } else if (count == 1) {
        // Don't let the load balancer have a web tag as well, causes problems
        let lb = this.object.servers.find(server => { return server.tags.includes("load_balancer") })
        return !lb.tags.includes("web")

      } else {
        return false
      }
    },

    validDatabase() {
      return this.serversWithTag("postgresql") > 0 ||
        this.serversWithTag("mysql") > 0 ||
        this.serversWithTag("mongodb") > 0
    },

    validVPC() {
      // If it's not EC2, skip
      if (this.object.provider != "ec2") { return true }

      // If we're creating a new VPC, then we're good
      if (this.object.vpc_id == null || this.object.vpc_id.id == null) { return true }

      // If we selected a VPC, we need a subnet ID
      return !!this.object.subnet_id
    }
  },
}
</script>
