<template>
</template>

<script>
  let options = {
    heroku: {
      servers: [
        { name: "Hobby", price: 7, detail: "512MB RAM" },
        { name: "Standard 1X", price: 25, detail: "512MB RAM" },
        { name: "Standard 2X", price: 50, detail: "1GB RAM" },
        { name: "Performance M", price: 250, detail: "2.5GB RAM" },
        { name: "Performance L", price: 500, detail: "14GB RAM" },
      ],
        postgres: [
          { name: "Free", price: 0, detail: "10K rows" },
          { name: "Basic", price: 9, detail: "10M rows" },
          { name: "Standard 0", price: 50, detail: "2 GB RAM, 64 GB storage, 120 connections" },
          { name: "Standard 2", price: 200, detail: "8 GB RAM, 256 GB storage, 400 connections" },
          { name: "Standard 3", price: 400, detail: "15 GB RAM, 512 GB storage, 500 connections" },
          { name: "Standard 4", price: 750, detail: "30 GB RAM, 750 GB storage, 500 connections" },
          { name: "Standard 5", price: 1400, detail: "61 GB RAM, 1 TB storage, 500 connections" },
          { name: "Standard 6", price: 2000, detail: "122 GB RAM, 1.5 TB storage, 500 connections" },
          { name: "Standard 7", price: 3500, detail: "244 GB RAM, 2 TB storage, 500 connections" },
          { name: "Standard 8", price: 4500, detail: "488 GB RAM, 3 TB storage, 500 connections" },
        ],
        redis: [
          { name: "Hobby", price: 0, detail: "25 MB RAM, 20 connections" },
          { name: "Redis Premium 0", price: 15, detail: "50 MB RAM, 40 connections" },
          { name: "Redis Premium-1", price: 30, detail: "100 MB RAM, 80 connections" },
          { name: "Redis Premium-2", price: 60, detail: "250 MB RAM, 200 connections" },
          { name: "Redis Premium-3", price: 120, detail: "500 MB RAM, 400 connections" },
          { name: "Redis Premium-5", price: 200, detail: "1 GB RAM, 1000 connections" },
          { name: "Redis Premium-7", price: 750, detail: "5 GB RAM, 5000 connections" },
          { name: "Redis Premium-9", price: 1450, detail: "10 GB RAM, 5000 connections" },
        ]
    },

      hatchbox: {
        servers: [
          { name: "1GB", price: 5, detail: "1GB RAM, 1 vCPU" },
          { name: "2GB", price: 10, detail: "2GB RAM, 1 vCPU" },
          { name: "4GB", price: 20, detail: "4GB RAM, 2 vCPUs" },
          { name: "8GB", price: 40, detail: "8GB RAM, 4 vCPUs" },
          { name: "16GB", price: 80, detail: "16GB RAM, 6 vCPUs" },
          { name: "32GB", price: 160, detail: "32GB RAM, 8 vCPUs" },
        ],
      }
    }

  options.hatchbox.configurations = [
    {
      name: "Load Balanced Cluster",
      servers: [
        { size: options.hatchbox.servers[0], tags: ["Load Balancer"] },
        { size: options.hatchbox.servers[0], tags: ["Web Server", "Cron"] },
        { size: options.hatchbox.servers[0], tags: ["Web Server"] },
        { size: options.hatchbox.servers[0], tags: ["Background Workers"] },
        { size: options.hatchbox.servers[2], tags: ["PostgreSQL", "Redis"] },
      ]
    },
    {
      name: "Single Server",
      servers: [
        { size: options.hatchbox.servers[2], tags: ["Web Server", "Cron", "Background Workers", "PostgreSQL", "Redis"] },
      ]
    },
  ]

  export default {
    props: ['roles'],
    data() {
      return {
        options: options,
        selected: {
          heroku: {
            servers: [0, 0, 3, 0, 0],
            postgres: options.heroku.postgres[2],
            redis: options.heroku.redis[1],
          },
          hatchbox: {
            configuration: options.hatchbox.configurations[0],
          }
        }
      }
    },

    computed: {
      heroku_total() {
        let total = 0

        this.selected.heroku.servers.forEach( (count, i) => {
          total += this.options.heroku.servers[i].price * count
        })

        total += this.selected.heroku.postgres.price
        total += this.selected.heroku.redis.price

        return total
      },

      hatchbox_total() {
        let total = 0

        this.selected.hatchbox.configuration.servers.forEach( (server, i) => {
          total += server.size.price
        })

        total += this.hatchbox_cost

        return total
      },

      hatchbox_cost() {
        var count = this.selected.hatchbox.configuration.servers.length
        if (count <= 2) {
          return 29
        } else if (count > 2 && count <= 4) {
          return 49
        } else {
          return 99
        }
      },
    }
  }
</script>
